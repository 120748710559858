import React from 'react';
import classnames from 'classnames';
import Icon from '../../atoms/BrandIcon';
import { NotificationType } from '../../../common/constants/notificationType';
import { SUCCESS_TITLE_ENDING } from './notifications.constants';
import styles from './notifications.module.css';

export interface INotification {
    id?: number;
    title: string;
    message: string;
    htmlMessage?: string;
    type?: NotificationType;
    onCloseClick?: (id: number) => void;
}

export default function Notification(props: INotification): JSX.Element {
    const { id, title, message, htmlMessage, type, onCloseClick } = props;

    const htmlToDisplay = { __html: htmlMessage };

    if (type === NotificationType.SUCCESS) {
        return (
            <div role="alert" className={classnames(styles.notification, styles.successNotification)}>
                <div className={styles.notificationContent}>
                    <Icon className={styles.notificationIcon} icon="action-check" />
                    <div className={styles.textBlock}>
                        {title && (
                            <span className={classnames('t-subheader-smaller', styles.title)}>
                                {title}
                                {SUCCESS_TITLE_ENDING}
                            </span>
                        )}
                        {htmlMessage ? (
                            <span
                                className={classnames('t-subheader-smaller', styles.message)}
                                dangerouslySetInnerHTML={htmlToDisplay}
                            />
                        ) : (
                            <span className={classnames('t-subheader-smaller', styles.message)}>{message}</span>
                        )}
                    </div>
                </div>
                <Icon
                    icon="action-close"
                    size="xs"
                    className={styles.closeIcon}
                    aria-label="close"
                    role="img"
                    onClick={() => onCloseClick(id)}
                />
            </div>
        );
    }

    return (
        <div role="alert" className={classnames(styles.notification, styles.errorNotification)}>
            <div className={styles.notificationContent}>
                <Icon className={styles.notificationIcon} icon="info-error" variant="light" />
                {title && <span className={classnames('t-subheader-smaller', styles.title)}>{title}:</span>}
                {htmlMessage ? (
                    <span
                        className={classnames('t-subheader-smaller', styles.message)}
                        dangerouslySetInnerHTML={htmlToDisplay}
                    />
                ) : (
                    <span className={classnames('t-subheader-smaller', styles.message)}>{message}</span>
                )}
            </div>
            <Icon
                icon="action-close"
                size="xs"
                className={styles.closeIcon}
                aria-label="close"
                role="img"
                onClick={() => onCloseClick(id)}
            />
        </div>
    );
}
