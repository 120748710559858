import React, { useCallback, useEffect, useState } from 'react';
import useNotifications from '../../../redux/hooks/useNotifications';
import useHover from '../../../common/hooks/useHover';
import Notification from './notification';
import { NotificationType } from '../../../redux/notifications';
import { NOTIFICATION_SHOW_TIME } from '../../../common/constants/notification';

const DEFAULT_ERROR_MESSAGE = 'Unknown Error';

export default function Notifications(): JSX.Element | null {
    const { notifications, actions } = useNotifications();
    const [timeoutId, setTimeoutId] = useState(null);
    const [isHovered, bind] = useHover();

    const handleCloseClick = useCallback((id) => {
        actions.remove({ id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (!isHovered && notifications.length > 0 && notifications[0].closeByDefault) {
            const timeoutId = setTimeout(() => actions.dequeue(), NOTIFICATION_SHOW_TIME);
            setTimeoutId(timeoutId);
        }

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHovered, notifications.length]);

    const defaultMessageMap = {
        [NotificationType.ERROR]: DEFAULT_ERROR_MESSAGE,
        [NotificationType.SUCCESS]: '',
    };

    return (
        <div {...bind}>
            {notifications.slice(-1).map((notification) => (
                <Notification
                    id={notification.id}
                    title={notification.title}
                    message={notification.message || defaultMessageMap[notification.type]}
                    htmlMessage={notification.htmlMessage}
                    type={notification.type}
                    onCloseClick={handleCloseClick}
                    key={notification.id}
                />
            ))}
        </div>
    );
}
